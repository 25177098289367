import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Axios from "axios";

Vue.use(VueRouter);

// 解决点击相同路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: "/",
    redirect: "/batchprint"
  },
  // 批量打印
  {
    path: "/batchprint",
    name: "batchprint",
    component: () => import("@/views/prints/batchprint"),
    meta: {
      id: 1
    }
  },
  // 单个打印
  {
    path: "/singleprint",
    name: "singleprint",
    component: () => import("@/views/prints/singleprint"),
    meta: {
      id: 2
    }
  },
  // 基础设置
  {
    path: "/setting",
    name: "setting",
    component: () => import("@/views/setting/index"),
    meta: {
      id: 3
    }
  },
  // 宝贝简称
  // {
  //   path: "/introduct",
  //   name: "introduct",
  //   component: () => import("@/views/introduct/index"),
  //   meta: {
  //     id: 4
  //   }
  // },
  // 备货单
  // {
  //   path: "/stocklist",
  //   name: "stocklist",
  //   component: () => import("@/views/StockList/index"),
  //   meta: {
  //     id: 5
  //   }
  // },
  // 查看教程
  {
    path: "/usetutorial",
    name: "usetutorial",
    component: () => import("@/views/UseTutorial/index"),
    meta: {
      id: 6
    }
  },
  // 实用工具
  {
    path: "/utilities",
    name: "utilities",
    component: () => import("@/views/Utilities/index"),
    meta: {
      id: 4,
    },
    children: [
      // 打印记录
      {
        path: 'printRecordSearch',
        component: () => import("@/views/Utilities/printRecordSearch.vue"),
        meta: {
          id: 4,
          sId: 1
        },
      },
      // 发货记录
      {
        path: 'deliverGoodsRecord',
        component: () => import("@/views/Utilities/deliverGoodsRecord.vue"),
        meta: {
          id: 4,
          sId: 2
        },
      },
    ]
  },
  // 购买记录
  {
    path: "/buyRecord",
    name: "buyRecord",
    component: () => import("@/views/buyRecord/index"),
    meta: {
      id: 8
    }
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
  store.commit("nav/save", { activeId: to.meta.id })
  // 设置activeId，用于激活当前路由对应的菜单栏按钮
  window.sessionStorage.setItem("activeId", to.meta.id);
  window.sessionStorage.setItem("activeSId", to.meta.sId);
  // // 访问登录页，放行
  // if (to.path === '/login') return next()
  // // 获取token
  // const tokenStr = window.sessionStorage.getItem('token')
  // // 没有token, 强制跳转到登录页
  // if (!tokenStr) return next('/login')

  let url = window.location.href
  let hash = window.location.hash
  let params = url.replace(hash,"")+'#'+to.fullPath
  
  Axios.post('/TopAuth/getPddPageCode', { referer: params }).then((res) => {
    (async function () {
      try {
        await PDD_OPEN_init({
          code: res.data  // 上一步中拿到的 pagecode，可选
        })
        window.self_pdd_pageCode = res.data
        window.self_pdd_pati = await window.PDD_OPEN_getPati()
        next()
      } catch (e) {
        next()
        console.log(e)
      }
    })()
  })
})

export default router;

