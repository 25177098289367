<template>
  <div id="app">
    <div class="app-container bg-color">
      <!-- 顶部导航 -->
      <topnavigation />
      <!-- 主体 -->
      <router-view />
      <!-- 右侧菜单 -->
      <!-- <right-tips/> -->
    </div>
    <appDialog @closePopup="()=>{this.dialogShow=false}" v-if="dialogShow"/>
    
  </div>
</template>

<script>
import appDialog from "@/components/appDialog";
import topnavigation from "@/components/TopNavigation";
import RightTips from "@/components/RightTips";
import pinduoduo_print from "@/utils/pinduoduo_print";

export default {
  components: { 
    topnavigation, 
    RightTips,
    appDialog
  },
  name: "App",
  data() {
    return {
      dialogShow: false
    };
  },
  methods: {
  },
  mounted() {
    // 获取一些必要的数据
    this.$store.dispatch("setting/getConfig")
    this.$store.dispatch('print/get_express_template');
    // this.$store.dispatch('print/get_fahuo_template');
    this.$store.dispatch('print/get_express_list');
    this.$store.dispatch('print/get_fahuo_consigner_list');
    this.$store.dispatch('print/get_express_consigner_list');

    // 初始化拼多多打印机组件
    let pinduoduo_params = new Object()
    pinduoduo_params.onmessage= (event) => {
      console.log(event, "---onmessage")
      let response = JSON.parse(event.data);
      if (response.cmd == 'getPrinters') { // 监听打印机列表
        let print_list = response.printers.map(item=>{
          return item.name
        })
        console.log(print_list,'---拼多多打印机列表获取成功')
        window.pinduoduo_print_list = print_list // 别管用不用得上,先保存一份打印机列表
      }else if(response.cmd == 'print'){ // 监听打印事件
        console.log(response,'---print')
      }else if(response.cmd == 'getTaskStatus'){ // 监听打印事件
        console.log(response,'---getTaskStatus')
      }else if(response.cmd == 'PrintResultNotify'){ // 监听打印结果反馈
        console.log(response,'---PrintResultNotify')
      }
    }
    pinduoduo_params.onerror = (res)=>{
      this.dialogShow = true
      window.pinduoduo_print_error == "提示: 拼多多打印组件无法链接"
    }
    pinduoduo_params.onclose = (res)=>{
      this.$message.error("提示: 拼多多打印组件无法链接");
      window.pinduoduo_print_error == "提示: 拼多多打印组件无法链接"
    }
    window.pinduoduo_print = new pinduoduo_print(pinduoduo_params)
  }
};
</script>
<style lang="less" scoped>
.app-container {
  height: 100%;
}


</style>
