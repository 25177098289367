import { getAuthorUrl, getToken } from "@/api/login.js";
export default function Token (){
  console.log("---dev_get_token")
  return new Promise((resolve, reject) => {
    let token=window.sessionStorage.getItem("token");
    if(!token || token == "null" || token=="undefined"){
      // 没有token code换token
      let href=window.location.search
      let params=href.split('?')[1]
      let url = new URLSearchParams(params);
      let code=url.get("code")
      if (code) {
        // 有code code换token
        let codeStr = code;
        // 获取token
        getToken({ code: codeStr }).then((res) => {
          var tokenStr = res.data.token;
          if(tokenStr!=null){
            window.sessionStorage.setItem("token", tokenStr);
          }
          resolve();
        }); 
      }else {// 没有code 跳转登录换code
        window.sessionStorage.removeItem("token");
        // 获取登录链接并跳转
        getAuthorUrl().then((res) => {
          if (res.code == 10019) {
            window.location.href = res.data.url;
          }
          reject();
        });
      }
    }else{
      resolve();
    }
  });
}