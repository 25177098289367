<template>
  <div class="navigation">
    <div class="right-nav">
      <ul>
        <li
          v-for="item in navList"
          :key="item.id"
          @click="toggleRoute(item.id, item.route)"
          :class="{ activeLi: item.id == activeId }"
        >
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="left-nav">
      <el-menu
        mode="horizontal"
        background-color="#f6891e"
        text-color="#fff"
        class="right-menu"
        @select="handleSelect"
      >

      <div v-if="shopInfoShow" :class="stroeInfoShow ?'stroeInfoShow' :'stroeInfoNo'" @mouseenter="stroeInfoShow = true" @mouseleave="stroeInfoShow = false">
        <span style="margin-right:10px">{{shopInfo.shop_name}}</span>
        <i class="el-icon-caret-bottom" v-show="!stroeInfoShow"></i>
        <i class="el-icon-caret-top" v-show="stroeInfoShow"></i>
        <div class="hideDiv" v-show="stroeInfoShow">
          <span>当前版本：{{shopInfo.edition ?shopInfo.edition :'--' }}</span>
          <div style="margin-top:10px">
            <span style="width:140px;">到期时间：{{shopInfo.expiration_time ?shopInfo.expiration_time :'--' }}</span>
            <div @click="jumpRenew">续费升级</div>
          </div>
        </div>
      </div>
        <el-menu-item
          index="2"
          class="right-menu white usetuorial"
          @click="useTutorial"
          >查看教程</el-menu-item
        >
        <!-- <el-menu-item index="3" class="right-menu update white" @click="exit"
          >退出</el-menu-item
        > -->
        <el-submenu index="3" class="dropmenu">
          <template slot="title">退出</template>
          <el-menu-item
            index="3-1"
            class="right-menu update white"
            @click="exit"
            >退出</el-menu-item
          >
          <!-- <el-menu-item
            index="3-2"
            class="right-menu update white"
            @click="backtrack"
            >返回市场</el-menu-item
          > -->
        </el-submenu>
        <el-menu-item
          index=" 4"
          class="right-menu white"
          @click="putOnTheDesktop('口袋宝打单','.url','http://pddprint.seable.cn/#/')"
          >放到桌面</el-menu-item
        >
        <!-- <el-menu-item
          index="5"
          class="right-menu white"
          :style="styleType"
          @click="buyRecordBtn"
          >记录/购买</el-menu-item
        > -->
        <!-- <p style="color:#fff;margin-right:10px">放到桌面</p>
        <p style="color:#fff" @click="$router.push('/buyRecord')">记录/购买</p> -->
      </el-menu>
      <!-- <el-image
        style="margin: 0 20px;"
        :src="OSS+'/app-logo.png'"
        fit="none"
      ></el-image> -->
       <el-image
        style="margin: 0 20px;"
        :src="require('@/assets/kd_logo.png')"
        fit="none"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import test_print_data from "./test_print_data";
// import {OSS} from '@/utils/PublicLink';

export default {
  name: "TopNavigation",
  computed: {
    ...mapState({
      shopInfo: (state) => state.setting.get_shop_info.data,
      navList: (state) => state.nav.navList,
    }),
    activeId:{
      get() {
        return this.$store.state.nav.activeId;
      },
      set(val) {
        this.$store.commit("nav/save", {activeId:val});
      },
    },
  },
  data() {
    return {
      stroeInfoShow: false,
      visiable: 1,
      styleType: '',
      shopInfoShow: false,
    };
  },
  methods: {
    test_button(){
      let params = {
        // printer: 'Microsoft Print to PDF',
        printer: 'Gprinter  GP-1124D',
        waybillArray: test_print_data
      }
      console.log(params,'---params')
      let task_id = window.pinduoduo_print.doPrint(params)
    },
    toggleRoute(id, route) {
      if(id == 4){
        this.activeId = id;
        this.$router.push(route+'/printRecordSearch');
      }else{
        this.activeId = id;
        this.$router.push(route);
      }
    },
    handleSelect() {},
    exit() {
      // 退出 清楚token并跳转授权页
      window.sessionStorage.removeItem("token");
      // // 获取登录链接并跳转
      // this.$store.dispatch('setting/getAuthorUrl').then(res=> {
      // // getAuthorUrl().then((res) => {
      //   if (res.code == 10019) {
      //     window.location.href = res.data.url;
      //   }
      // });
      // window.close();
    },
    useTutorial() {
      // const { href } = this.$router.resolve({ name: "usetutorial" });
      // window.open("usetutorial.html", "_blank");
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
      // let href=window.location.href
      // let params=href.split('?')[1]
      // let url = new URLSearchParams(params);
      // let code=url.get("code")
    },
    jumpRenew(){
      // window.open('https://fuwu.jinritemai.com/detail?service_id=23169&searchType=1&from=fxg_admin_home_tab&btm_pre=a0254.b7465.c8788&btm_ppre=a2427.b19885.c7641.header_menu_6&page_from=1g7tqbn5r_b7465')
    },
    putOnTheDesktop(a,b,c){
      var d = ''+a+b;
      var e = '[InternetShortcut]'+'\n'+'URL='+c;
      this.saveToDesktop(d,e);
    },
    saveToDesktop(fileName, data) {
        var explorer = navigator.userAgent.toLowerCase();
        if (explorer.indexOf("trident") > 0) {//IE 5+
            var wss = new ActiveXObject("WScript.Shell");
            var desktopUrl = wss.SpecialFolders("Desktop");
            var fso = new ActiveXObject("Scripting.FileSystemObject");
            var tf = fso.CreateTextFile(desktopUrl + "\\" + fileName, true);
            tf.Write(data);
            tf.Close();
        } else {//fireFox chrome
            var urlObj = window.URL || window.webkitURL || window;
            var blob = new Blob([data]);
            var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
            link.href = urlObj.createObjectURL(blob);
            link.download = fileName;
            saveClick(link);
        }

        function saveClick(obj) {
            var e = document.createEvent("MouseEvents");
            e.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
            );
            obj.dispatchEvent(e);
        }
    },
    buyRecordBtn() {
      // this.$router.resolve({ name: "buyRecord" })
      // this.$router.push('/buyRecord')
      let path = `${window.location.protocol}//${window.location.host}/#/buyRecord`
      window.open(path);
    },
    backtrack() {
      window.open("https://fuwu.jinritemai.com/")
    },
    // askhelp(){
    //   window.open("https://amos.alicdn.com/getcid.aw?spm=a261y.7663282.autotrace-topbar.154.4e6e66b9A4r8FI&v=3&groupid=0&s=1&charset=utf-8&uid=%E5%85%B4%E4%B8%9A%E5%AE%9D%E4%BA%91%E4%BB%93&site=cnalichn")
    // },
  },
  mounted() {
    this.$nextTick(function () {
      this.activeId = window.sessionStorage.getItem("activeId");
      this.$store.dispatch('setting/getShopInfo').then(()=> {
        this.shopInfoShow = true
      })
    });
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px !important;
}
.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-color: #f6891e;
  width: 100%;
  min-width: 1200px;
  height: 40px;
}
.right-nav {
  height: 100%;
}
.right-nav ul {
  display: flex;
  list-style: none;
  margin-left: 10px;
  margin-top: 7px;
}
.right-nav ul li {
  list-style: none;
  text-align: center;
  cursor: pointer;
  color: #fff;
  width: 62px;
  margin: 0px 5px;
  padding: 6px 5px;
  border: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  /* border-radius: 4px; */
}
.right-nav ul li::after,
.usetuorial::after {
  content: "|";
  position: relative;
  float: right;
  left: 10px;
  display: block;
}
.right-nav .activeLi {
  box-sizing: content-box;
  border-bottom: 5px solid #fff;
  color: #f6891e;
  box-shadow: inset 0 0 1px 1px #fff;
  font-weight: 1000;
  background-image: -webkit-linear-gradient(bottom, #fff, #e6e0d0);
}


.left-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-nav ul {
  margin: 0;
  padding: 0;
}
.right-menu {
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.white {
  color: white;
  font-size: 12px;
}
.left-nav >>> .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}
.left-nav >>> .el-submenu .el-submenu__title {
  font-size: 12px;
}
.left-nav >>> .el-menu--horizontal > .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  color: white;
}
.left-nav >>> .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: white;
}
/* .update{
  margin: 0 10px;
} */
.left-nav >>> .el-submenu .el-submenu__title i {
  color: #ffffff !important;
}
.stroeInfoShow {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  padding: 5px 3px 0 3px;
  background-color: #ffffcc;
  border: 1px solid #f8be9f;
  border-bottom: none;
  position: relative;
  z-index: 202;
  color: #f6891e;

}
.stroeInfoNo {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  padding: 5px 3px 0 3px;
  border-bottom: none;
  position: relative;
  z-index: 100;
  color: #ffff00;

}
.hideDiv {
  width: 230px;
  position: absolute;
  top: 29px;
  left: -1px;
  background-color: #ffffcc;
  border: 1px solid #f8be9f;
  border-top: none;
  border-radius: 0 0 8px 8px;
  color: #000;
  padding: 10px;
  cursor: default;
}
.hideDiv div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hideDiv div div {
  height: 30px;
  background-color: #ff740d;
  padding: 0 5px;
  line-height: 29px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  /* width: 68px; */
}
</style>