import { getLodop } from "@/plugins/Lodop.js";
import Vue from 'vue'

var lodop, printerList;

// 获取lodop对象
function getLodopObj() {
	return new Promise((resolve, reject) => {
		try {
			var lodopObj = getLodop();
			resolve(lodopObj);
		} catch (err) {
			reject(err);
		}
	})
}

// 设置lodop全局变量
function setLodop() {
	return new Promise((resolve, reject) => {
		getLodopObj().then((lodopObj) => {
			lodop = lodopObj;
			if (typeof lodop != 'undefined') {
				console.log('lodop对象获取成功');
			}
			resolve();
		}).catch((err) => {
			reject(err);
		})
	})
}

// 检查lodop版本
function checkLodopVersion() {
	return new Promise((resolve, reject) => {
		if ( lodop && (lodop.VERSION || lodop.CVERSION) ) {
			resolve(true);
		}else {
			resolve(false);
		}
	})
}

// 获取打印机列表
export function getPrinterList() {
	return new Promise((resolve, reject) => {
		try {
			var printers = [];
			var count = lodop.GET_PRINTER_COUNT();
			for (var i = 0; i < count; i++) {
				var printer = lodop.GET_PRINTER_NAME(i);
				printers.push(printer)
			}
			printerList = printers;
			if (typeof printerList != 'undefined') {
				console.log(printerList,'---lodop打印机列表获取成功');
			}
			resolve(printerList);
		} catch (err) {
			reject(err);
		}
	})
}

export let lodop_init = function () {
	return new Promise((resolve,reject)=>{
		setLodop().then(async () => {
			let lodop_status = await checkLodopVersion()
			console.log(lodop_status,'------lodop_status')
			getPrinterList();
			Vue.prototype.LODOP = lodop;
			Vue.prototype.PRINTERLIST = printerList;
			window.lodop_print = printerList;
			resolve(lodop_status)
		}).catch((err) => {
			reject(err);
		})
	})
}